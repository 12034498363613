import React, { Component } from "react";

export default class footer extends Component {
  render() {
    return (
      <div className="site-footer" id="footer">
        <div className="container">
            <p>&copy; {new Date().getFullYear()} All Rights Reserved by {this.props.siteName}</p>
          <p>Check the Privacy Policy from <a href="http://assets.ctfassets.net/sgc8uslaht6m/31hCNJhzGHydLl6vIp07vF/0f783ede4127b5d28e9191819bed81bd/EvrenTan-Website-PrivacyPolicy.pdf" target="_blank" rel="noopener noreferrer">here</a>.</p>
        </div>
      </div>
    );
  }
}
